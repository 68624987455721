<template>
    <div>
        <el-row>
            <el-col :span="12">
                <GgdCard background="#409eff" title="今日领取学员" :content="analysis.receive"></GgdCard>
                <GgdCard background="#ff88a7" title="今日回访次数" :content="analysis.access"></GgdCard>
                <GgdCard background="#7b5eea" title="待跟进学员" :content="track"></GgdCard>
            </el-col>
            <el-col :span="12">
                <el-tooltip v-if="serviceList.length > 0" v-for="(itemBtn,indexBtn) in receivebtnList" effect="dark"
                            :content="itemBtn.remarks"
                            placement="top">
                    <el-button :type="itemBtn.btnType" size="small"
                               @click="toRouter('StudentReceive', {id: itemBtn.btnId,name:itemBtn.label})">
                        {{ itemBtn.label }}
                    </el-button>
                </el-tooltip>
            </el-col>
        </el-row>
        <el-row>
            <el-form v-model="params" :inline="true" label-position="right" label-width="110px" size="mini">
                <div class="transition-box">
                    <el-form-item label="学员编号" prop="studentId">
                        <el-input v-model="params.studentId" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="学员姓名" prop="studentName">
                        <el-input v-model="params.studentName" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="学员备注" prop="remarks">
                        <el-input v-model="params.remarks" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="学员类型" prop="studentType">
                        <el-select v-model="params.studentType">
                            <el-option label="全部" value=""></el-option>
                            <el-option v-for="item in studentTypeList" :key="item.id"
                                       :value="item.dictValue" :label="item.dictLabel"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="下单手机号" prop="mobile">
                        <el-input v-model="params.mobile" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="商品名称" prop="goodsName">
                        <el-input v-model="params.goodsName" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="下单渠道" prop="orderChannel">
                        <el-select v-model="params.orderChannel">
                            <el-option label="全部" value=""></el-option>
                            <el-option v-for="item in orderChannelList" :key="item.id"
                                       :value="item.dictValue" :label="item.dictLabel"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="购买时间" prop="buyTime">
                        <el-date-picker
                                v-model="buyTimeList"
                                type="daterange"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <template>
                            <el-button type="primary" icon="el-icon-search" size="mini" @click="doSearch">搜索
                            </el-button>
                            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                        </template>
                    </el-form-item>
                </div>
            </el-form>
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-radio-group size="mini" v-model="servicesId" @change="doSearch">
                    <el-radio-button :label="item.servicesId" v-for="item in serviceList">
                        {{ item.name }}
                    </el-radio-button>
                </el-radio-group>
            </el-col>
            <el-col :span="12" style="text-align: right">
                <el-button type="primary" size="mini" v-permission="'waiter:student:add'" @click="handleAdd">添加
                </el-button>
                <el-button type="primary" size="mini" v-permission="'waiter:student:uploadApply'" @click="uploadAppond">
                    基本信息导入
                </el-button>
                <!--                <el-button type="primary" size="mini" @click="resetQuery">选择组内服务号</el-button>-->
                <el-button type="primary" size="mini" v-permission="'waiter:student:worker'">
                    <a style="text-decoration:none;color:inherit;"
                       href="https://y0vlq7dl91w.feishu.cn/wiki/Lf70wfbv1iooAPk1Z8hch6pwnnb"
                       target="_blank">排班信息</a>
                </el-button>
            </el-col>
        </el-row>
        <el-table :data="dataList" style="width: 100%" stripe fit max-height="640" v-loading="loading">
            <el-table-column align="center" label="主键" prop="studentId">
              <template v-slot="scope">
                <div style="display:flex;">
                  <div v-if="!scope.row.firstTime" class="badge">新</div>
                  <div class="text-student">{{scope.row.studentId}}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column type="expand">
                <template v-slot="scope">
                    <el-form label-width="120px" inline size="mini">
                        <el-form-item label="从事相关工作">
                            <el-switch
                                    v-model="scope.row.worked"
                                    @change="changeCheck(scope.row,'worked')"
                                    active-text="是"
                                    inactive-text="否">
                            </el-switch>
                        </el-form-item>
                        <el-form-item label="是否加钉钉">
                            <el-switch
                                    v-model="scope.row.dingding"
                                    @change="changeCheck(scope.row,'dingding')"
                                    active-text="是"
                                    inactive-text="否">
                            </el-switch>
                        </el-form-item>
                        <el-form-item label="是否建档">
                            <el-switch
                                    v-model="scope.row.record"
                                    @change="changeCheck(scope.row,'record')"
                                    active-text="是"
                                    inactive-text="否">
                            </el-switch>
                        </el-form-item>
                        <el-form-item label="是否发送协议">
                            <el-switch
                                    v-model="scope.row.sendProtocol"
                                    @change="changeCheck(scope.row,'sendProtocol')"
                                    active-text="是"
                                    inactive-text="否">
                            </el-switch>
                        </el-form-item>
                        <el-form-item label="是否签署协议">
                            <el-switch
                                    v-model="scope.row.signProtocol"
                                    @change="changeCheck(scope.row,'signProtocol')"
                                    active-text="是"
                                    inactive-text="否">
                            </el-switch>
                        </el-form-item>
                        <el-form-item label="是否进群">
                            <el-switch
                                    v-model="scope.row.crowd"
                                    @change="changeCheck(scope.row,'crowd')"
                                    active-text="是"
                                    inactive-text="否">
                            </el-switch>
                        </el-form-item>
                        <el-form-item label="是否进入架构">
                            <el-switch
                                    v-model="scope.row.framework"
                                    @change="changeCheck(scope.row,'framework')"
                                    active-text="是"
                                    inactive-text="否">
                            </el-switch>
                        </el-form-item>
                        <el-form-item label="是否进入校长群">
                            <el-switch
                                    v-model="scope.row.rectorCrowd"
                                    @change="changeCheck(scope.row,'rectorCrowd')"
                                    active-text="是"
                                    inactive-text="否">
                            </el-switch>
                        </el-form-item>
                        <el-form-item label="是否报名">
                            <el-switch
                                    v-model="scope.row.enroll"
                                    @change="changeCheck(scope.row,'enroll')"
                                    active-text="是"
                                    inactive-text="否">
                            </el-switch>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column align="center" label="学员姓名" prop="studentName"></el-table-column>
            <el-table-column align="center" label="首次沟通时间" prop="firstTime"></el-table-column>
            <!--            <el-table-column align="center" label="学员类型" prop="studentType">-->
            <!--                <template v-slot="scope">-->
            <!--                    <span v-for="item in studentTypeList" :key="item.id"-->
            <!--                          v-if="scope.row.studentType === item.dictValue">{{ item.dictLabel }}</span>-->
            <!--                </template>-->
            <!--            </el-table-column>-->
            <el-table-column align="center" label="下单手机号" prop="mobile">
                <template v-slot="scope">
                    <span>{{ scope.row.mobile }}
                        <el-button type="text" v-if="!scope.row.showMobile" @click="handleView(scope.row)"><i
                                class="el-icon-view"></i></el-button>
                    </span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="服务号" prop="servicesId">
                <template v-slot="scope">
                    <span v-for="item in serviceList" :key="item.servicesId"
                          v-if="scope.row.servicesId === item.servicesId">{{ item.name }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="学员备注" prop="remarks"></el-table-column>
            <el-table-column align="center" label="操作" width="200px">
                <template v-slot="scope">
                    <!--                    <el-button size="mini" type="text" @click="resetQuery">直呼</el-button>-->
                    <!--                    <el-button size="mini" type="text" @click="resetQuery">双呼</el-button>-->
                    <el-button type="text" size="mini" icon="el-icon-user" @click="info(scope.row)"
                               v-permission="'waiter:student:info'">详情
                    </el-button>
                    <el-button size="mini" type="text" icon="el-icon-s-promotion" @click="handleTransfer(scope.row)">转移</el-button>
                    <el-button size="mini" type="text" v-permission="'waiter:student:access'"
                               @click="handleAccess(scope.row)">快速回访
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
                @size-change="findPage"
                @current-change="findPage"
                :current-page="params.current"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="params.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>

        <el-dialog title="添加数据" :visible.sync="dataFromSync" width="60%" top="20px">
            <el-form :model="dataFrom" :rules="dataFromRules" ref="dataFrom" style="width: 500px" label-width="150px"
                     size="medium">
                <el-form-item label="手机号" prop="mobile">
                    <el-input v-model="dataFrom.mobile"></el-input>
                </el-form-item>
                <el-form-item label="学员姓名" prop="studentName">
                    <el-input v-model="dataFrom.studentName"></el-input>
                </el-form-item>
                <el-form-item label="成单类型" prop="orderType">
                    <el-radio-group v-model="dataFrom.orderType">
                        <el-radio :label="1">基础</el-radio>
                        <el-radio v-for="item in pondList" :key="item.id" v-if="item.id > 1" :label="item.id">
                            {{ item.name }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="学员类型" prop="studentType">
                    <el-select v-model="dataFrom.studentType" filterable style="width: 100%">
                        <el-option v-for="item in studentTypeList" :key="item.id" :value="item.dictValue"
                                   :label="item.dictLabel"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="成单销售" prop="orderSalesName">
                    <el-select v-model="dataFrom.orderSalesName" filterable style="width: 100%">
                        <el-option v-for="item in accountList" :key="item.id" :value="item.id"
                                   :label="item.nickName"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="成单价格" prop="orderPrice">
                    <el-input v-model="dataFrom.orderPrice"></el-input>
                </el-form-item>
                <el-form-item label="商品名称" prop="goodsName">
                    <el-input v-model="dataFrom.goodsName"></el-input>
                </el-form-item>
                <el-form-item label="下单渠道" prop="orderChannel">
                    <el-select v-model="dataFrom.orderChannel" filterable style="width: 100%">
                        <el-option v-for="item in orderChannelList" :key="item.id" :value="item.dictValue"
                                   :label="item.dictLabel"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="订单编号" prop="orderNo">
                    <el-input v-model="dataFrom.orderNo"></el-input>
                </el-form-item>
                <el-form-item label="购买时间" prop="buyTime">
                    <el-date-picker style="width: 100%"
                                    v-model="dataFrom.buyTime"
                                    type="datetime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="班主任服务号" prop="servicesId">
                    <el-select v-model="dataFrom.servicesId" filterable style="width: 100%">
                        <el-option v-for="item in serviceList" :key="item.servicesId" :value="item.servicesId"
                                   :label="item.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="学员备注" prop="remarks">
                    <el-input v-model="dataFrom.remarks" type="textarea" :rows="4"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="medium" @click="submitForm('dataFrom')">确 定</el-button>
                <el-button size="medium" @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

        <el-drawer
                title="详情"
                :visible.sync="infoDrawer"
                v-if="infoDrawer"
                size="50%"
                style="overflow: auto;"
                :append-to-body="true"
                direction="rtl">
            <el-row style="text-align: center">
                <el-col :span="2">
                    <div class="grid-content"></div>
                </el-col>
                <el-col :span="7">主键：{{ studentInfo.studentId }}</el-col>
                <el-col :span="7">姓名：{{ studentInfo.studentName }}</el-col>
                <!--                <el-col :span="7">性别：{{studentInfo.sex}}</el-col>-->
                <el-col :span="1">
                    <div class="grid-content"></div>
                </el-col>
            </el-row>
            <el-tabs v-model="activeName" type="card">
                <el-tab-pane label="基本信息" name="info">
                    <WaiterStudentDetailRecord :student-info="studentInfo" :edit="true"
                                               v-if="activeName === 'info'"></WaiterStudentDetailRecord>
                </el-tab-pane>
                <el-tab-pane label="回访记录" name="access">
                    <WaiterStudentAccessRecord :services-id="servicesId" :student-id="studentInfo.studentId"
                                               :edit="true" v-if="'access' === activeName"></WaiterStudentAccessRecord>
                </el-tab-pane>
                <el-tab-pane label="售前操作记录" name="sales">
                    <WaiterStudentSaleRecord :edit="true" v-if="'sales' === activeName"
                                             :student-id=studentInfo.studentId></WaiterStudentSaleRecord>
                </el-tab-pane>
                <el-tab-pane label="服务记录" name="service">
                    <WaiterStudentServiceRecord :edit="true" :record-pro="studentInfo"
                                                v-if="'service' === activeName"></WaiterStudentServiceRecord>
                </el-tab-pane>
            </el-tabs>
        </el-drawer>

        <WaiterAccessDetail v-if="accessRecord.visible" :student-id="studentId" :callback="findPage"
                            :services-id="servicesId"
                            :detail="accessRecord"></WaiterAccessDetail>

        <UploadStudent :student-pro="uploadPro" :callback="findPage"></UploadStudent>

      <!--   分配     -->
      <AllotStudent :allot-pro="allotPro" :student-list="studentList" title="转移学员"
                    :callback="findPage"></AllotStudent>
    </div>
</template>

<script>
import * as studentApi from "@/api/waiter/waiterStudent"
import * as receiveApi from "@/api/waiter/waiterReceiveBtn"
import * as serviceApi from "@/api/waiter/waiterServices"
import * as dataApi from "@/api/waiter/waiterDataAnalysis"
import * as accountApi from "@/api/system/account"
import * as pondApi from "@/api/clue/cluePond"
import * as dictionaryApi from "@/api/system/dictionary"
import UploadStudent from "@/views/waiter/detail/UploadStudent.vue";

const GgdCard = () => import("@/components/GgdCard");
const WaiterStudentAccessRecord = () => import("@/views/waiter/detail/WaiterStudentAccessRecord");
const WaiterStudentDetailRecord = () => import("@/views/waiter/detail/WaiterStudentDetailRecord");
const WaiterStudentSaleRecord = () => import("@/views/waiter/detail/WaiterStudentSaleRecord");
const WaiterStudentServiceRecord = () => import("@/views/waiter/detail/WaiterStudentServiceRecord");
const WaiterAccessDetail = () => import("@/views/waiter/detail/WaiterAccessDetail");
const AllotStudent = () => import("@/views/waiter/detail/AllotStudent");

export default {
    name: "WaiterMyStudent",
    components: {
        UploadStudent,
        GgdCard,
        WaiterStudentAccessRecord,
        WaiterStudentDetailRecord,
        WaiterStudentSaleRecord,
        WaiterStudentServiceRecord,
        WaiterAccessDetail,
      AllotStudent
    },
    data() {
        return {
          studentList:[],
          allotPro:{
            show:false
          },
            accessSync: false,
            servicesId: 0,
            studentId: 0,
            studentInfo: {},
            activeName: "info",
            infoDrawer: false,
            receivebtnList: [],
            track: 0,
            analysis: {
                access: 0,
                receive: 0
            },
            statusList: [{label: '正常', value: 0}, {label: '禁用', value: 9}],
            dataFrom: {
                servicesId: null,
                orderType: 1,
            },
            dataFromRules: {
                mobile: [{required: true, message: '请输入手机号', trigger: 'blur'}],
                orderType: [{required: true, message: '请选择成单类型', trigger: 'blur'}],
                studentType: [{required: true, message: '请选择学员类型', trigger: 'blur'}],
                // orderSalesName: [{required: true, message: '请选择成单销售', trigger: 'blur'}],
                // orderPrice: [{required: true, message: '成单价格', trigger: 'blur'}],
                goodsName: [{required: true, message: '商品名称', trigger: 'blur'}],
                orderChannel: [{required: true, message: '下单渠道', trigger: 'blur'}],
                orderNo: [{required: true, message: '订单编号', trigger: 'blur'}],
                buyTime: [{required: true, message: '购买时间', trigger: 'blur'}],
                servicesId: [{required: true, message: '班主任服务号', trigger: 'blur'}],
            },
            dataFromSync: false,
            dataFromAdd: true,
            params: {
                current: 1,
                size: 10
            },
            loading: false,
            dataList: [],
            total: 0,
            buyTimeList: [],
            idList: [],
            serviceList: [],
            accountList: [],
            pondList: [],
            studentTypeList: [],
            orderChannelList: [],
            accessRecord: {
                visible: false,
            },
            uploadPro: {
                show: false,
            },
        }
    },
    mounted() {
        this.myData()
        this.findService()
        this.findAccount()
        this.findPond()
        this.findStudentType()
        this.findOrderChannel()
        this.findReceiveList()

    },
    methods: {
      /**
       * 转移
       */
      handleTransfer(row){
        this.studentList = (row.studentId ? [row] : null) || this.studentList
        this.allotPro.show = true
      },
        notAccess() {
            studentApi.notAccess(this.servicesId).then(res => {
                if (res.success) {
                    this.track = res.data
                }
            })
        },
        myData() {
            dataApi.myData().then(res => {
                if (res.success) {
                    this.analysis = res.data
                }
            })
        },
        /**
         * 改变值
         */
        changeCheck(row, key) {
            let params = {studentId: row.studentId}
            params[key] = row[key]
            studentApi.saveData(params).then(res => {
                if (res.success) {
                    this.$message.success(res.msg)
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        uploadAppond() {
            this.uploadPro = {
                show: true,
                title: "学员追加信息",
                action: "/api/manage/waiter/student/uploadAppend",
                template: "/api/manage/waiter/student/downloadTemplateAppend"
            }
        },
        toRouter(name, params) {
            this.$store.dispatch('setHeaderTitle', params.name)
            this.$router.push({
                name: name,
                params: params
            })
        },
        handleView(row) {
            studentApi.viewMobile(row.studentId).then(res => {
                if (res.success) {
                    row.mobile = res.data
                    row.showMobile = true
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        handleAccess(row) {
            this.studentId = row.studentId
            this.accessRecord.visible = true
        },
        info(row) {
            this.infoDrawer = true
            this.studentInfo = row
        },
        findReceiveList() {
            receiveApi.findPage().then(res => {
                if (res.success) {
                    this.receivebtnList = res.data.records
                }
            })
        },
        findStudentType() {
            dictionaryApi.findChildrenByCode('studentType').then(res => {
                if (res.success) {
                    this.studentTypeList = res.data
                }
            })
        },
        findOrderChannel() {
            dictionaryApi.findChildrenByCode('orderChannel').then(res => {
                if (res.success) {
                    this.orderChannelList = res.data
                }
            })
        },
        findPond() {
            pondApi.listCluePond().then(res => {
                if (res.success) {
                    this.pondList = res.data
                }
            })
        },
        findAccount() {
            accountApi.list().then(res => {
                if (res.success) {
                    this.accountList = res.data
                }
            })
        },
        findService() {
            serviceApi.findMyService().then(res => {
                if (res.success) {
                    this.serviceList = res.data
                    this.servicesId = localStorage.getItem("servicesId")
                    if (this.serviceList && !this.servicesId) {
                        this.servicesId = this.serviceList[0].servicesId
                        localStorage.setItem("servicesId", this.servicesId)
                    }
                    this.servicesId = this.servicesId * 1
                    this.findPage()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        /**
         * 取消
         */
        cancel() {
            this.dataFromSync = false
        },
        /**
         * 保存
         */
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    studentApi.saveData(this.dataFrom).then(res => {
                        if (res.success) {
                            this.$message({
                                type: "success",
                                message: '保存成功！'
                            });
                            this.dataFromSync = false
                            this.findPage()
                        } else {
                            this.$message({
                                type: "error",
                                message: res.msg
                            });
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        /**
         * 添加
         */
        handleAdd() {
            this.dataFromSync = true
            this.dataFrom = {
                servicesId: this.servicesId,
                orderType: 1,
            }
        },
        /**
         * 重置
         */
        resetQuery() {
            this.params = {
                current: 1,
                size: 10,
                status: 0,
            }
            this.buyTimeList = []
            this.doSearch()
        },
        /**
         * 点击搜索
         */
        doSearch() {
            this.params.current = 1
            this.findPage()
            localStorage.setItem("servicesId", this.servicesId)
        },
        /**
         * 分页查询数据
         */
        findPage() {
            this.loading = true
            this.params.servicesId = this.servicesId
            if (this.buyTimeList) {
                this.params.buyTimeStart = this.buyTimeList[0]
                this.params.buyTimeEnd = this.buyTimeList[1]
            }
            this.notAccess(this.servicesId)
            studentApi.findMyPage(this.params).then(res => {
                this.loading = false
                if (res.success) {
                    this.dataList = res.data.records
                    this.total = res.data.total
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.el-pagination {
  bottom: 10px;
  position: fixed;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

/deep/ .el-drawer {
  overflow: auto;
}

.badge {
  position: relative;
  background-color: #f00; /* 角标背景颜色 */
  border-radius: 50%; /* 角标是圆形的 */
  width: 20px; /* 角标宽度 */
  height: 20px; /* 角标高度 */
  color: #fff; /* 角标内的文字颜色 */
  text-align: center; /* 文字居中 */
  line-height: 20px; /* 行高与高度相同，使文字垂直居中 */
  font-size: 14px; /* 文字大小 */
}

.badge::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -10px; /* 调整角标位置 */
  transform: translateY(-50%);
  //border: 10px solid transparent; /* 创建三角形 */
  border-top-color: #f00; /* 三角形颜色 */
}

.text-student{
  width: 100%;
  text-align: center;
  line-height: 43px;
}
</style>